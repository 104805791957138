.disease-subjects {
    background-color: #F3F3F3;
    height: auto;
}

#disease-subjects .content {
    margin-top: 20px !important;
}
.diseasesCards {
    width: 130px;
    height: 100px;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #ffffff;
}

.diseasesCards .card-title {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #005B96;
    word-wrap: break-word
}

.diseasesCards img {
    /* margin-bottom: 20px; */
    width: 40px;
    height: 40px;
}

.custom-col {
    flex: 0 0 12.5%;
    max-width: 12.5%;
}