.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .success-modal {
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 2px solid #12D76E;
    text-align: center;
    width: 30%;
  }
  
  .success-icon {
    height: 100px;
  }

  .congrats {
    color: #12D76E;
    font-size: 20px;
  }