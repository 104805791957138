.contact-card {
    background-color: #F3F3F3;
}
.name-info {
    color: white;
}

.left_side {
    height: auto;
    color: #eee;
    background: #011f4b;
    text-align: center;
    display: grid;
  }
  .right_side {
    padding: 40px 40px 40px 40px;
    background: #ffffff;
  }
  
.contact-details p {
  color: white !important;
}  