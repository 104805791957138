.mobile-navbar {
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5rem; /* 24px */
  padding-right: 1.5rem; /* 24px */
  padding-top: 0.5rem; /* 8px */
  padding-bottom: 0.5rem; /* 8px */
  margin-bottom: 0.75rem; /* 12px */
}

.overlay {
  height: 100vh;
  position: fixed;
  inset: 0;
  z-index: 10;
  overflow: hidden;
}
.menu {
  position: absolute;
  inset: 0;
  background-color: #ffffff;
}
.menu-title {
  color: rgb(59 130 246);
  font-weight: 500;
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}
.menu-link {
  background: transparent;
  color: black;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1.75rem; /* 12px */
  width: 100%;
  padding: 0.5rem; /* 8px */
  margin-left: 20px;
  margin-top: 20px;
  border-bottom: 1px solid lightgray;
}
.menu-text {
  width: 100%;
  flex: 1 1 0%;
  color: #005B96 !important;
}

.crossimage {
  height: 20px;
}

.menu-content-wrapper {
    height: calc(100vh - 15vh);
    overflow-y: auto;
  }

  .userImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .user-info {
    padding-left: 20px;
  }