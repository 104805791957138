.accordion-button:not(.collapsed) {
    color: black;
    background-color: white;
}

.accordion-button:focus {
    border-color: red !important;
    border: none;
}

.dont-provide ul{
    list-style:none;
    text-align: left;
}

.dont-provide li {
    font-weight: 700;
    font-size: 16px;
}