.simple-card {
    position: relative;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    background-color: #f3f3f3 !important;
}
.simple-card .card-header {
    border-radius: 8px 8px 0 0;
    padding: 0.5rem 0 0;
}
.simple-card .card-title {
    margin-bottom: 0.5rem;
}
.simple-card .card-subtitle {
    margin-bottom: 0.5rem;
}
.simple-card .card-title-primary {
    margin-bottom: 0.5rem;
}
.simple-card .card-img {
    border-radius: 8px 8px 0;
}
.simple-card .card-body {
    padding: 0.5rem 0;
}
.simple-card.card-border {
    padding: 0.5rem 1rem 0.5rem 2rem;
}
.simple-card.card-border .card-title {
    margin-bottom: 0.5rem;
}
.simple-card.card-border .card-subtitle {
    margin-bottom: 0.3rem;
}
.simple-card.card-border .card-title-primary {
    margin-bottom: 0.3rem;
}
.simple-card.card-border .card-body {
    padding: 0.5rem 0;
}
.simple-card.card-border .card-footer {
    padding: 0.5rem 0;
}
.simple-card.card-border::before {
    position: absolute;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: blue;
    width: 8px;
    height: 100%;
    left: 0;
    top: 0;
}
.simple-card .card-footer {
    padding: 0.5rem 0;
    background-color: transparent;
    align-items: center;
    border: 0;
}