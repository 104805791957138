.my-doctors-card {
    background-color: #f3f3f3;
    width: 100%;
    height: auto;
    padding: 20px;
}

.cardimage {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

@media (max-width: 600px){
    .cardimage {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .btn_join {
        font-size: 12px !important;
    }   
}