.custom-heading {
  font-size: 20px;
}




/* .table tr:last-child{
  border: none;
}

table td:last-child 
{ 
  border: none
} */


/* Search Feild */
.search-group {
  position: relative;
}
.search-group .btn {
  border: 1px solid #005B96;
  color: black;
  border-radius: 10px;
  background-color: #f3f3f3;
}
.search-group .btn:first-child {
  border-right: 0;
}
.search-group .btn:last-child {
  border-left: 0;
}
.search-group .form-control {
  background-color: #f3f3f3;
  border: 1px solid #005B96;
  border-radius: 10px;
}
.search-group .form-control:first-child {
  border-right: 0;
}
.search-group .form-control:last-child {
  border-left: 0;
}


/* Pagination CSS */
.pagination{
  position: absolute;
  background-color: white;
  width: 100%;
  height: 20;
  bottom: 0%;
}
.pagi-btn{
  background-color: transparent;
  border: transparent;
  height: 40px;
  display: flex;
  align-items: center;
}
.form-select{
  height: 40px !important;
  width: 70px !important;
  border: none !important;
}
.form-select:hover{
  border: none !important;
}

.image-btn{
  height: 15px;
  width: 15px;
}

.fixed-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  border-top: 2px solid #DFE0EB!important;

  padding: 10px 18px;
}

.fixed-footer p {
  margin-bottom: 0;
  line-height: 40px;
}
.fixed-footer {
  position: static;
}


.btn-view {
  background-color: #005B96;
  color: white;
}

.btn-view:hover {
  background-color: #011F4B;
  color: white;
}