.profileSection {
  background-color: #f3f3f3;
}
.verified-icon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}
.fw-bolder {
  display: flex;
  align-items: center;
}
.reviewImg {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 1em;
}

.footer-pannel {
  padding: 50px 0;
}

.reviews p {
  color: #FFC805;
  font-weight: 700;
  font-size: 16px;
}

.five {
  font-size: 90px;
  color: #FFC805;
}

.about p{
  text-align: justify !important;
}

.profileSection .badge {
  display: inline-block;
  padding: 8px 10px;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50px !important;
  border: 2px solid #005B96 !important; 
  color: #005B96 !important; 
  background: transparent;
  margin-right: 0.5em; /* adds a right margin */
  margin-bottom: 0.5em; /* adds a bottom margin */
}

@media (max-width: 768px) {
  .profileimg {
    height: 160px;
  }
}

.profile-table {
  text-decoration: none;
  table-layout: fixed;
  width: 100%;
}

.profile-table thead th:nth-child(1) {
  width: 40%;
  border-bottom-width: none;
}

.profile-table thead th:nth-child(2) {
  width: 30%;
  border-bottom-color: none;
}

.profile-table thead th:nth-child(3) {
  width: 30%;
  border-bottom-color: none;
}


@media (max-width: 425px) {
  .profileimg {
    text-align: center !important;
  }
  table {
    font-size: 14px;
  }
  .dr-info {
    margin-top: 15px;
  }
}
