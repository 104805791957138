.auth-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .auth-logo {
    max-width: 70%;
    max-height: 100%;
  }

  .auth-passwordfield {
    position: relative;
  }
  .auth-passwordfield .eyeicon {
    position: absolute;
    right: 10px;
    top: 50%; 
    transform: translateY(-50%);
    cursor: pointer;
    height: 18px;
    filter: invert(18%) sepia(97%) saturate(2223%) hue-rotate(186deg) brightness(97%) contrast(101%);
  }
  
  @media (max-width: 599px) {
    .auth-logo-container {
      margin-top: 25px;
    }
    .auth-logo {
      max-width: 50%;
      max-height: 100%;
    }
  }
  