#why-us {
    background-color: #FFFFFF;
}
#why-us .content {
    padding: 0px 120px 0 0px;
}
#why-us .content h2 {
    text-align: left !important;
}
#why-us .content p {
    text-align: justify;
}

#why-us .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 8px !important;
    /* min-height: 600px; */
}

.steps-count {
    align-self: center;
    align-items: center;
    font-size: 22px;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #005B96;
    color: white;
  }

  .text-container {
    padding-left: 20px;
}


@media (max-width: 1024px) {
    #why-us .content {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    #why-us .img {
        min-height: 400px;
    }

    #why-us .content {
        padding-top: 30px;
    }
}

@media (max-width: 575px) {
    #why-us .img {
        min-height: 200px;
    }
}