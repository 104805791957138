.notificationToggle {
  align-items: center;
  color: #343a40;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 6px 0;
}

.settingsPage .about a:not(:last-of-type) {
  border-bottom: 1px solid #e9ecef;
}

.setting-info{
  display: block;
  padding: 16px;
}

.setting-info:hover {
  background-color: #F8F9FA;
}

.description {
    color: #868e96;
    font-size: 12px;
}