.chartheader h5 {
    background-color: #005B96; 
    display: inline-block; 
    padding: 15px 10px;
    color: white !important;
    width: 100%;
}

.btn-link {
    background: #03396C;
    width: 30%;
    height: 36px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    border: none;
  }