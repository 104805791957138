.vitalscard {
    width: 165px;
    height: 130px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #F5DFDF;
    position: relative;
}

/* .vitalscard .card-title {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #005B96;
    word-wrap: break-word
} */

.vitalscard img {
    width: 50px;
    height: 50px;
}

.nexticon {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 15px;
}