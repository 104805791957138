.footer-section {
  background-color: #011f4b;
  height: auto;
  padding-bottom: 3%;
}

.footer-section h1 {
  font-weight: 700;
  font-size: 36px;
  color: white;
  padding-top: 3%;
}

.footer-section h2 {
  font-weight: 700;
  font-size: 28px;
  color: white;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.logo2image {
  height: 80px;
}

.footer-links {
  text-decoration: none;
  color: #ffffff !important;
}

.footer-links:hover {
    text-decoration: none;
    color: #c1272d !important;
  }

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .center-align {
    margin-left: 130px;
  }
  .logo2image {
    height: 70px !important;
  }
  .footer-section h2 {
    font-weight: 700;
    font-size: 24px;
    color: white;
  }
  .footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    font-size: 16px !important;
  }
  .footer-section {
    background-color: #011f4b;
    height: auto !important;
    padding-bottom: 0%;
  }
}

@media (max-width: 480px) {
  .center-align {
    margin-left: 0px;
  }
  .logo2image {
    height: 50px !important;
  }
  .footer-section h2 {
    font-weight: 700;
    font-size: 20px;
    color: white;
  }
  .footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    font-size: 14px !important;
  }
  .footer-section {
    background-color: #011f4b;
    height: auto !important;
    padding-bottom: 0%;
  }
}
