.textarea {
  min-height: 100px;
}
.css-1s2u09g-control {
  background-color: #f3f3f3 !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 10px !important;
}


/* Profile Image CSS */
.profilePIc {
  width: 100% !important;
  height: 100% !important;
  max-width: 150px !important;
  max-height: 150px !important;
  border-radius: 50%;
  border: 1px solid #005b96;
}

.outer {
  width: 100% !important;
  height: 100% !important;
  max-width: 150px !important; 
  max-height: 150px !important;
  margin: auto;
  background-color: #ffffff;
  border-radius: 100%;
  position: relative;
  }
  
.inner {
  background-color: #005b96;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.inner:hover {
  background-color: #011F4B;
}

.inputfile {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
}
.inputfile + label {
    font-size: 1.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    width: 50px;
    height: 50px;
    pointer-events: none;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
}
.inputfile + label svg {
    fill: #fff;
}


/* .container-img {
  width: 150px;
  height: 150px;
  display: block;
  margin: 0 auto;
} */

/* img {
  width: auto;
  height: 100%;
} */

/* .custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  margin-bottom: 25px;
} */

/* .img-wrap{
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
} */

/* .img-upload:before{
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}
.img-upload:hover:before{
 opacity: 1;
} */



/* File Uploading */
.file-upload {
  background-color: #f3f3f3;
  width: 100%;
}

.image-upload-wrap {
  border: 1px solid #005b96;
  position: relative;
  width: 100% !important;
  border-radius: 5px;
}

.file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: #005b96;
  border: none;
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid #011F4B;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: #011F4B;
  border-bottom: 4px solid #005b96;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all .2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: #005b96;
  border: 1px solid #ffffff;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 100;
  text-transform: uppercase;
  color: #15824B;
  padding: 60px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all .2s ease;
}