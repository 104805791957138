.provider-content {
    justify-content: left;
    align-items: left;
    text-align: left;
}
.provider-card{
    background-color: #f3f3f3;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.08);
   
}

.provider-card:hover {
    border-radius: 6px;
    box-shadow: 0 2px 4px 1px rgba(0,0,0,.2);
}