.custom-card {
    background: #f3f3f3;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 30px;
}
/* .custom-card .card-header {
    background-color: #f3f3f3;
} */
.custom-card .card-header:first-child {
    border-radius: 5px 5px 0 0;
}
.custom-card .card-header .card-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.3px;
    margin-bottom: 0;
}
.custom-card .card-header .btn i {
    display: inline;
    margin-left: 7px;
}