.this-month-card {
    border: 1px solid #D8E1FC;
    background-color: #D8E1FC;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;  
}

.this-month-card2 {
    border: 1px solid #E3E8EC;
    background-color: #E3E8EC;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px; 
}

.this-month-content {
    display: flex;
    flex-direction: column;
}