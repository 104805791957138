.sidebar {
  width: 16rem; /* 256px */
  height: 100%;
  min-height: 100vh;
  background-color: #011f4b;
  color: #ffffff;
  /* position: fixed; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* 24px */
  padding: 1.5rem; /* 24px */
  max-height: 100vh;
    overflow-y: auto;
}
.sidebar-link {
  background: transparent;
  color: #ffffff !important;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0.5rem; /* 8px */
  padding-bottom: 0.5rem; /* 8px */
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  gap: 0.75rem; /* 12px */
}
.sidebar .active,
.sidebar-link:hover {
  background-color: rgba(179, 205, 224, 0.5);
}
.sidebar-text {
  width: 100%;
  flex: 1 1 0%;
  color: white !important;
}
.sidebar-message-chip {
  border-radius: 9999px;
  background-color: red;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  font-size: 8px;
  width: 1.25rem; /* 20px */
  height: 1.25rem; /* 20px */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.iconlogoimage:first-child {
  height: 50px;
  margin-left: 12px;
}
.iconlogoimage:last-child {
  display: none;
}

@media only screen and (max-width: 992px) {
  .sidebar {
    width: 5rem;
    padding: 0.25rem; /* 4px */
  }
  .iconlogoimage:first-child {
    display: none;
  }
  .iconlogoimage:last-child {
    display: block;
    margin-top: 20px;
    height: 50px;
    margin-left: 17px;
  }
}
