.bmi {
  background-color: #f3f3f3;
  position: relative;
}
.bmi-image {
    width: 100%;
    height: 250px;
}

#antbits-bmi-results_bar {
  position: relative;
  height: 60px; /* Adjusted for space above the bar for the pointer */
  width: 100%;
}

#antbits-bmi-results_bar_bg {
  position: absolute;
  top: 20px; /* Push it down to make space for the pointer on top */
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../../../public/assets/vitals/slider_adult.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

#antbits-bmi-results_bar_pointer {
  position: absolute;
  top: 0; /* Position at the top of the container */
  background-image: url("../../../../public/assets/vitals/bar-pointer.png");
  background-repeat: no-repeat;
  background-position: center top; /* Centered horizontally, at the top vertically */
  background-size: 0.875em 1.25em;
  height: 20px; /* Adjust as per your pointer image height */
  width: 0.875em; /* Adjust as per your pointer image width */
  z-index: 2;
  transform: translateX(-50%); /* Center the pointer on its position */
  transition: left 0.5s ease-out;
}

#antbits-bmi-results_bar_labels {
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 3;
}

#antbits-bmi-results_bar_labels > div {
  position: absolute;
  top: 0px;
  left: 0%;
  border-left: 1px solid #212b32;
  height: 0.9375em;
}

#antbits-bmi-results_bar_labels > div > div {
  position: absolute;
  top: 18px;
  left: -20px;
  width: 2.5em;
  text-align: center;
  font-size: 1em;
}

.form-input-field {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #005b96;
  border-radius: 10px;
  margin-bottom: 8px;
  margin-top: 8px;
}
.form-input-field:focus {
  border: 1px solid #005b96;
}

.label {
  color: #005b96;
  font-weight: 500;
}

.swtichbutton {
    background: none;
    border: none;
}

.swtichbutton:hover {
    background: none;
    border: none;
    color: #005b96;
}

.bmi-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  background-color: #fff;
}

.bmi-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  transform: translateY(-5px);
}
.bmi-card img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 14px;
}