.profile-img {
    text-align: center;
}
.profile-img img {
    width: 50%;
    border-radius: 100%;
}
.form-heading {
    color: #005B96 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}