#provide-hero {
  width: 100%;
  height: 60vh;
  background: url("../../../../../public/assets/webImages/profile.PNG") top center;
  background-size: cover;
  position: relative;
  padding: 0;
}

#provide-hero:before {
  content: "";
  background: linear-gradient(
    270deg,
    rgba(1, 31, 75, 0) 3.58%,
    rgba(1, 31, 75, 0.206207) 62.21%,
    rgba(1, 31, 75, 0.5) 96.42%,
    rgba(1, 31, 75, 0.34654) 100%
  );
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#provide-hero .provide-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#provide-hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #fff;
}

#provide-hero p {
  color: #fff;
  font-weight: 700;
}
@media (min-width: 1024px) {
  #provide-hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #provide-hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
}
