/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: linear-gradient(
      270deg,
      rgba(1, 31, 75, 0) 3.58%,
      rgba(1, 31, 75, 0.28869) 48.4%,
      rgba(1, 31, 75, 0.7) 96.42%,
      rgba(1, 31, 75, 0.485156) 100%
    ),
    url("../../../../public/assets/webImages/back.PNG") fixed center center;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  background-size: cover;
  padding: 120px 0;
}

.cta h4 {
  font-weight: 700;
  font-size: 48px !important;
  color: #ffffff;
}

.cta .cta-btn {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #47b2e4;
  border: 2px solid #47b2e4;
}

/* cta.style.css */
.cta-button {
  border-radius: 20px;
  padding: 10px 30px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  background: #005B96;
  border: 2px solid #005B96;
  text-decoration: none;

}

.cta-button:hover {
  border-color: #fff;
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
}


@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}

@media (max-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .cta h4 {
    font-weight: 700;
    font-size: 42px !important;
    color: #ffffff;
  }
}

@media (max-width: 425px) {
  .cta h4 {
    font-weight: 700;
    font-size: 26px !important;
    color: #ffffff;
  }
}