/* @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans'); */
/* @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: Open Sans; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: 'Poppins', cursive;
}


/* Global TAGS CSS  */
h1 {
  color: #005B96;
  font-size: 36px;
  font-weight: 700;
  line-height: 56px;
}

h2 {
  font-weight: 700;
  font-size: 24px;
  color: #005B96;
}

h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

h4 {
  color: #005B96;
}

h5{
  color: #005B96;
}

h6 {
  font-weight: 400;
  font-size: 20px;
  color: #000000;
}

p {
  font-size: 16px;
  color: #333333;
}

span {
  color: #005B96 !important;
}

a {
  text-decoration: none !important;
}

.grey-container {
  background: #f3f3f3;
  position: relative;
  height: auto;
}

.tight {
  margin-bottom: 0;
  line-height: 1.5;
}

.badge {
  background-color: #bdc1c5;
}

/* Form Inputs  Starts*/
.form-input {
  width: 100%;
  height: 40px;
  background: #f3f3f3;
  border: 1px solid #005B96;
  border-radius: 10px;
  margin-bottom: 8px;
  margin-top: 8px;
}
.form-input:focus {
  border: 1px solid #005B96 ;
}

.placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  padding-left: 11px;
  color: #333333;
}

.form_btn {
  width: 100%;
  height: 40px;
  background: #03396C;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  display: flex;         
  justify-content: center; 
  align-items: center;
}

.form_btn:hover {
  border-color: #011F4B;
  background: #011F4B;
  color: white;
}

/* Form Inputs  Ends*/

/* Buttons CSS Start */

.link-button {
  font-weight: 700;
  font-size: 22px;
  color: #005B96;
  text-decoration: none;
}

.btn-find-doctor {
  font-weight: 700;
  font-size: 24px;
  display: inline-block;
  padding: 10px 35px 10px 35px;
  border-radius: 20px;
  transition: 0.5s;
  color: #fff;
  background: #005B96;
  border: 2px solid #005B96;
  text-decoration: none;
}

.btn-find-doctor:hover {
  border-color: #011F4B;
  background: #011F4B;
  color: white;
}

.modal_btn {
  background: #03396C;
  color: #ffffff;
  border: none;
  height: 32px;
}

.modal_btn:hover {
  border-color: #011F4B;
  background: #011F4B;
  color: white;
}


.btn_join {
  background: #03396C;
  width: 100%;
  height: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  border: none;
}

.btn_join:hover {
  border-color: #011F4B;
  background: #011F4B;
  color: white;
}

.btn-outline {
  border-color: #fff;
  color: #005B96;
  width: 100%;
  height: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid;
}

.btn-outline:hover {
  border-color: #BDC1C5;
  background: #BDC1C5;
  color: white;
}

/* Buttons CSS Ends */

/* Media Quiries */
@media (max-width: 768px) {
  h1 {
    color: #005B96 ;
    font-size: 30px !important;
    font-weight: 700;
    line-height: 56px;
  }

  h2 {
    font-weight: 700;
    font-size: 26px;
    color: #005B96;
  }
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #000000;
  }
  p {
    font-size: 14px;
    color: #333333;
  }
  .link-button {
    font-weight: 700;
    font-size: 20px;
    color: #005B96;
    text-decoration: none;
  }
}

@media (max-width: 428px) {
  h1 {
    color: #005B96;
    font-size: 28px !important;
    font-weight: 700;
    line-height: 56px;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    color: #005B96;
  }
  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
  }
  p {
    font-size: 14px;
    color: #333333;
  }

  .link-button {
    font-weight: 700;
    font-size: 16px;
    color: #005B96;
    text-decoration: none;
  }

}


/* Desktop Table CCS */

.table-responsive {
  overflow-y: auto !important;
}

.table-responsive .table-full-width {
  min-width: 1030px;
}

.header-table{
  background-color: #EFEFEF !important;
  height: 69px;
  border-top: 2px solid #DFE0EB;
  vertical-align: none !important;


}
.table>thead {
  vertical-align: middle !important; 
}

.table-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.table thead tr th {
  font-style: bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #8E91B1; 
  font-weight: 600;
  border: none;
}

thead th:nth-child(1) {
  width: 25%;
}

thead th:nth-child(2){
  width: 15%;
}

thead th:nth-child(3) {
  width: 15%;
}

thead th:nth-child(4) {
  width: 15%;
}

thead th:nth-child(5) {
  width: 10%;
}


thead th:nth-child(6) {
  width: 20%;
}

.table-responsive table tr td, .table-responsive table tr th {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 15px;
  vertical-align: middle;
}

.table-responsive table tr th {
  border-bottom: 1px solid #ddd;
  padding: 15px;
}

/* .table-responsive table tr:last-child td {
  border-bottom: 0;
} */

/* Responsive Table */
@media (min-width: 1199px) {
  .table-responsive {
    overflow: inherit !important;
 }
  .table-responsive table {
    max-width: 100%;
 }
  .table-responsive table tr td, .table-responsive table tr th {
    white-space: normal;
    word-break: break-all;
 }
}
@media (max-width: 1199px) {
  .table-responsive {
    overflow-x: auto;
    overflow-y: auto;
 }
}


/* Mobile View Table */

.table-mobile {
  display: block;
  color: #005b96;
  width: 100%;
  margin: auto;
  background-color: #f3f3f3;
}

.table-mobile img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.table-mobile .title {
  font-weight: bolder !important;
}

.appointment-content {
  margin-top: 18px;
}
.table-mobile .top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border-bottom: 1px solid #666666;
}

.table-mobile .middle {
  padding: 10px 20px 10px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #666666;
  text-align: center;
}

.middle p {
  font-weight: 200;
}

.table-mobile .top-section .img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-mobile .top-section .img p {
  padding-top: 0.6rem;
  margin-left: 0.6rem;
}

.table-mobile .bottom {
  display: flex;
  flex-direction: row-reverse;
}

.table-mobile .bottom button {
  border: none;
  font-size: 0.9rem;
  padding: 0 0.6rem;
  padding-bottom: 0.1rem;
  border-radius: 5px;
  font-weight: 300;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 0.6rem;
}

.cancel {
  /* background-color: #ca0606;
  color: white;
  font-weight: 500; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ca0606;
  color: white;
  font-weight: 300;
  border: none;
  font-size: 0.9rem;
  padding: 0 0.6rem;
  padding-bottom: 0.1rem;
  border-radius: 5px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 0.6rem;
}
.cancel:hover {
  color: white;
} 
.join {
  /* background-color: #0b7402;
  color: white;
  font-weight: 500; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0b7402;
  color: white;
  font-weight: 300;
  border: none;
  font-size: 0.9rem;
  padding: 0 0.6rem;
  padding-bottom: 0.1rem;
  border-radius: 5px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 0.6rem;
}
.join:hover {
  color: white;
}
.outline-btn {
  font-size: 0.9rem;
  padding: 0 0.6rem;
  padding-bottom: 0.1rem;
  border-radius: 5px;
  font-weight: 300;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 0.6rem;
  background-color: transparent;
  border: 2px solid #011f4b !important;
  transition: background-color 0.2s, color 0.2s;
  color: #011f4b;
}

.details {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #011f4b;
  color: white;
  font-weight: 300;
  border: none;
  font-size: 0.9rem;
  padding: 0 0.6rem;
  padding-bottom: 0.1rem;
  border-radius: 5px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 0.6rem;
}

.details:hover {
  color: white;
}
@media (max-width: 768px) {
  .desktop-table {
    display: none;
  }
}

@media (min-width: 769px) {
  .table-mobile {
    display: none;
  }
}


/* Calender CSS */
.custom-calander {
  border-bottom: 1px solid #ddd;
}

.custom-calander .rbc-toolbar .rbc-toolbar-label {
	 text-align: left;
	 padding: 0;
	 font-style: normal;
	 font-size: 20px;
	 line-height: 27px;
}
 .custom-calander .rbc-toolbar .rbc-btn-group:first-child {
	 position: absolute;
	 right: 15px;
}
 .custom-calander .rbc-toolbar .rbc-btn-group:first-child button {
	 border: 0;
	 font-size: 0;
	 padding: 0 5px;
}
 
 .custom-calander .rbc-toolbar .rbc-btn-group:last-child {
	 display: none;
}
 .custom-calander .rbc-month-view {
	 border: 0;
}
 .custom-calander .rbc-month-row {
	 border-top: 1px solid #ddd;
}
 .custom-calander .rbc-month-row .rbc-day-bg:first-child {
	 border-left: 1px solid #ddd;
}
 .custom-calander .rbc-month-row .rbc-day-bg:last-child {
	 border-right: 1px solid #ddd;
}
.custom-calander .rbc-date-cell {
  display: flex;
  justify-content: center;  /* center horizontally */
  align-items: center;      /* center vertically */
  margin-top: 10px;
}

.rbc-row.rbc-month-header .rbc-header {
	font-size: 0; 
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
  }
  .rbc-row.rbc-month-header .rbc-header:nth-child(1)::before { content: "Sun"; }
  .rbc-row.rbc-month-header .rbc-header:nth-child(2)::before { content: "Mon"; }
  .rbc-row.rbc-month-header .rbc-header:nth-child(3)::before { content: "Tue"; }
  .rbc-row.rbc-month-header .rbc-header:nth-child(4)::before { content: "Wed"; }
  .rbc-row.rbc-month-header .rbc-header:nth-child(5)::before { content: "Thr"; }
  .rbc-row.rbc-month-header .rbc-header:nth-child(6)::before { content: "Fri"; }
  .rbc-row.rbc-month-header .rbc-header:nth-child(7)::before { content: "San"; }
  .rbc-row.rbc-month-header .rbc-header::before {
	font-size: 1rem;
	visibility: visible;
	color: #005B96;
  }
.dot-event {
	display: inline-block;
	width: 7px;
	height: 7px;
	background-color: #03396C;
	border-radius: 50%;
	margin-bottom: 15px;
  }

.rbc-event {
	background-color: transparent !important;
	border: none !important;
	display: flex; 
	justify-content: center;
	align-items: center;
  }
