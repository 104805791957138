#landing-banner {
    width: 100%;
    height: 90vh;
    background: url("../../../../public/assets/webImages/landing.jpg") top center;
    background-size: cover;
    position: relative;
    padding: 0;
}

#landing-banner:before {
    content: "";
    background: linear-gradient(
      45deg,
      rgba(1, 31, 75, 0) 3.58%,
      rgba(1, 31, 75, 0.206207) 62.21%,
      rgba(1, 31, 75, 0.5) 96.42%,
      rgba(1, 31, 75, 0.34654) 100%
    );
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    /* position: absolute; */
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

#landing-banner:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#landing-banner .landing-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#landing-banner h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    text-transform: uppercase;
    color: #fff;
}

#landing-banner h2 {
    color: #eee;
    margin-bottom: 50px;
    font-size: 24px;
}

#landing-banner .btn-find-doctor:hover {
    border-color: #fff;
    color: #005B96;
    background: rgba(255, 255, 255, 0.1);
}

@media (min-width: 1024px) {
    #landing-banner {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    #landing-banner h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #landing-banner h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }
}

@media (max-width: 425) {
    .mobile-header {
        display: inline-block;
      }
}