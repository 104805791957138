.next-generation {
    background: #F3F3F3;
}

.next-generation .card {
    padding-top: 20px !important;
    border: none !important;
    background-color: white;
}

.next-generation .card img {
    align-self: center !important;
    width: 85px !important;
    height: 89px !important;
}

.next-generation .card-text {
    color: #333333;
}

.next-generation .card-title {
    font-weight: 700;
    font-size: 20px;
}