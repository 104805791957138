#blog-detail {
    margin-top: -100px;
}

.content-wrapper {
    background-color: white;
    height: auto;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.user-column img {
    width: 115px;
    height: 115px;
    border-radius: 50%;

}

.user-column h2 {
    font-weight: 700;
    font-size: 20px;
}

.user-column p {
    font-size: 19px;
}

/* .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
} */

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.details-panel {
    border-bottom: 1px solid #ddd;
    padding: 30px 0;
}

.details-panel h1 {
    font-weight: 700;
    font-size: 48px;
}

.details-panel p{
    font-size: 20px;
} 

.lignheight-26 {
    line-height: 26px;
}

.blogs-description {
    text-align: justify;
    margin-right: 10%;
}

.lists li {
    color: black;
}

.content-wrapper h4{
    margin-right: 10%;
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .blogs-description {
        text-align: justify;
        margin-right: 5%;
        margin-left: 5%;
    }
    .content-wrapper h4{
        margin-right: 5%;
        margin-left: 5%;
    }
    .details-panel {
        text-align: center;
    }
}
@media (max-width: 599px) {
    .blogs-description {
        text-align: justify;
        margin-right: 5%;
        margin-left: 5%;
    }
    .content-wrapper h4{
        margin-right: 5%;
        margin-left: 5%;
    }
    .details-panel {
        text-align: center;
    }
  }
  