/* .testCard {
    width: 100%;
    height: auto;
    background-color: #F3F3F3;
    box-shadow: 1px 1px #F3F3F3;
    padding-bottom: 5px;
    padding-top: 0;
    margin-bottom: 30px;
  }
  .testCard img {
    height: 220px;
    width: 100%;
    object-fit: cover;
  }

  .name{
    font-size: 20px;
    font-family: "Poppins";
  }
  .fee{
    text-align: left;
    justify-content: left;
    align-items: flex-end;
    font-size: 15px;
  }
  .badge{
    background-color: #BDC1C5;
  }
  .p-container p {
    margin-bottom: 5px;
  } */

.filter-card {
  background-color: #f3f3f3;
  position: sticky;
  top: 90px;
  padding: 20px 8px 20px 8px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 80vh;
}

.my-doctors p {
  text-align: justify;
}

@media (max-width: 768px) {
  .filter-card {
    display: none;
  }
}

.btn-view {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  border-radius: 5px;
  background: #005B96 !important;
  width: 100%;
  height: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  border: none;
}

.btn-view:hover {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  border-radius: 5px;
  background: #03396C !important;
  width: 100%;
  height: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  border: none;
}

.form-input-field {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #005B96;
  border-radius: 10px;
  margin-bottom: 8px;
  margin-top: 8px;
}
.form-input-field:focus {
  border: 1px solid #005B96 ;
}