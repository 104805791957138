.center-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.center-wrapper h1 {
    color: white !important;
    font-size: 28px !important;
}
.image-logo {
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.image-logo img {
    max-width: 100%; 
    height: 150px;
}

.socialmedia-images h4 {
    color: white !important;
    margin-bottom: 20px;
}
.socialmedia-images img {
    width: 30px;
    height: 30px;
}

.container-codes {
    max-width: 800px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    background-color: #011f4b;
    text-align: center;
}
.otp-code {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    letter-spacing: 8px; 
    color: white;
}

.instructions {
    font-size: 16px;
    margin-bottom: 20px;
    color: #888;
}

.footer {
    font-size: 14px;
    color: #888;
}


.center-wrapper .btn {
    background-color: #005B96; 
    color: #ffffff; 
    padding: 12px 24px; 
    border-radius: 5px; 
    border: none; 
    cursor: pointer; 
    font-size: 16px; 
    font-weight: 600; 
    transition: background-color 0.3s ease; 
    text-decoration: none;
    display: inline-block; 
    margin-bottom: 20px;
}

.center-wrapper .btn:hover {
    background-color: #007BFF; 
}

.center-wrapper .btn:active {
    background-color: #007BFF; 
}

.center-wrapper .btn:disabled {
    background-color: #a9a9a9; 
    cursor: not-allowed; 
}
