.react-tabs__tab-list {
    border: none !important;
}

.react-tabs__tab--selected,
.react-tabs__tab--selected:focus,
.react-tabs__tab--selected:active:focus {
    border: none !important;
    border-bottom: 2px solid #005B96 !important;

}

.react-tabs__tab:focus:after {
    display: none !important;
}

