/*  */

#step-1 .content {
    padding: 80px 120px 0 0px;
}
#step-1 .content h2 {
    text-align: left !important;
}
#step-1 .content p {
    text-align: justify;
}

.counting {
    align-self: center;
    align-items: center;
    font-size: 22px;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #005B96;
    color: white;
  }

#step-1 .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 8px !important;
    min-height: 350px;
}


@media (max-width: 1024px) {
    #step-1 .content {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    #step-1 .img {
        min-height: 400px;
    }

    #step-1 .content {
        padding-top: 30px;
    }
    #step-1 .content h2 {
        text-align: center !important;
    }
    #step-1 .content p {
        text-align: center;
    }
    #step-1 .number-center {
        text-align: center;
    }
    .number-center {
        display: flex;
        justify-content: center;
        align-items: center; 
    }
}

@media (max-width: 575px) {
    #step-1 .img {
        min-height: 200px;
    }
    #step-1 .content h2 {
        text-align: center !important;
    }
    #step-1 .content p {
        text-align: center;
    }
    .number-center {
        display: flex;
        justify-content: center;
        align-items: center; 
    }
}