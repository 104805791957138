.servicesCards {
    width: 177px;
    /* height: 80px; */
    border-radius: 5px;
    background-color: #F3F3F3;
    padding: 10px; /* Add padding inside card */
}

.servicesCards .card-title {
    text-align: left; /* change to left */
    font-weight: 500;
    font-size: 14px;
    color: #005B96;
    word-wrap: break-word;
    margin: 0; /* remove default margin */
}

.servicesCards img.card-image {
    width: 40px;
    height: 40px;
}

.card-text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


