.wroking-steps {
    background-color: #F3F3F3;
    width: 100%;
    height: auto;
    background-size: cover;
    padding: 30px 0 30px 0;
    position: relative;
    z-index: 15;
}

.wroking-steps::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #F3F3F3;
    z-index: 15;
}

.wroking-steps .card-text {
    margin-top: 35px;
}

.wroking-steps .container,
.wroking-steps .container-fluid {
    position: relative;
    z-index: 15;
}

.counting {
    align-self: center;
    align-items: center;
    font-size: 22px;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #005B96;
    color: white;
  }

 .wroking-steps .card {
    padding-top: 20px;
    border: none;
    background: transparent;
  }

  .wroking-steps .card-text{
      text-align: justify;
  }

  .wroking-steps .card-title {
    font-weight: 700;
    font-size: 20px;
  }

@media (max-width: 1024px) {
    .wroking-steps {
        background-attachment: scroll;
    }
}