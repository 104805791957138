.diseases-treated {
    display: inline-block;
    padding: 8px 10px;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50px !important;
    border: 2px solid #005B96 !important; 
    color: #005B96 !important; 
    background: transparent;
    margin-right: 0.5em; /* adds a right margin */
    margin-bottom: 0.5em; /* adds a bottom margin */
  }