.greeting-card {
    background-color: #f3f3f3 !important;
    padding: 25px 10px;
    margin-bottom: 30px;
    display: table;
    width: 100%;
    border: 1px #e5e5e5 solid;
    overflow: visible;
    box-sizing: border-box;
}

.greeting-date {
    margin-top: 17px;
    padding-right: 15px;
  }