.appointment {
    background-color: #f3f3f3;
    height: 310px;
    border-radius: 5px;
    
}

.heading-banner {
    background-color: #011F4B;
    width: 100%;
    border-radius: 5px 5px 0 0;
    height: 49px;
    padding: 10px;
}

.app-head {
    color: white;
    text-align: center;
}

.radio-button-label {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
  
  .radio-button-label input[type="radio"] {
    margin-right: 10px;
  }