#diseases-banner {
    width: 100%;
    height: 100vh;
    background: url("../../../../public/assets/webImages/about.png") top center;
    background-size: cover;
    position: relative;
    padding: 0;
  }
  
  #diseases-banner:before {
    content: "";
    /* background: rgba(13, 20, 26, 0.7); */
    background: linear-gradient(
      270deg,
      rgba(1, 31, 75, 0) 3.58%,
      rgba(1, 31, 75, 0.206207) 62.21%,
      rgba(1, 31, 75, 0.5) 96.42%,
      rgba(1, 31, 75, 0.34654) 100%
    );
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  .diseases-container {
    /* position: absolute; */
    margin-top: 40px;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    text-align: left;
    height: 20vh;
  }

  #diseases-banner .diseases-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  
  #diseases-banner h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    text-transform: uppercase;
    color: #fff;
  }
  
  #diseases-banner h2 {
    color: #eee;
    margin-bottom: 50px;
    font-size: 24px;
  }
  @media (min-width: 1024px) {
    #diseases-banner {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 768px) {
    #diseases-banner h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #diseases-banner h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  