#blogs .card{
    background-color: #f3f3f3;
    border-radius: 15px;
}
#blogs .card img {
    border-radius: 15px 15px 0px 0px !important;
}

#blogs .card-title{
    text-align: left;
}

#blogs .card-text{
    text-align: justify;
}

#blogs .linkblog-button {
    text-decoration: none;
    color: #005B96;
}