#footer-nav {
    background-color: #011F4B;
    color: white;
    height: auto;
}

#footer-nav h5 {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 2%;
    padding-top: 2px;
    color: white !important;
}

@media (max-width: 786px) {
    #footer-nav h5 {
        font-weight: 700;
        font-size: 16px;
        color: white !important;
    }
}