/* Add these styles in your CSS file */
.status-cell {
    font-weight: bold; /* Optional: add styles for all cells */
  }
  
  .status-cell.pending {
    color: orange; /* Style for pending status */
  }
  
  .status-cell.rejected {
    color: red; /* Style for rejected status */
  }
  
  .status-cell.approved {
    color: green; /* Style for approved status */
  }
  