#ongoing-care {
    background-color: #F3F3F3;
}
#ongoing-care .content {
    padding: 0px 120px 0 0px;
}
#ongoing-care .content h2 {
    text-align: left !important;
}
#ongoing-care .content p {
    text-align: justify;
}

#ongoing-care .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 8px !important;
    /* min-height: 600px; */
}

@media (max-width: 1024px) {
    #ongoing-care .content {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    #ongoing-care .img {
        min-height: 400px;
    }

    #ongoing-care .content {
        padding-top: 30px;
    }
}

@media (max-width: 575px) {
    #ongoing-care .img {
        min-height: 200px;
    }
}