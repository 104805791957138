/* .earning-card {
    background-color: #fff;
    padding: 25px 10px;
    margin-bottom: 30px;
    display: table;
    width: 100%;
    border: 1px #e5e5e5 solid;
    overflow: visible;
    box-sizing: border-box;
}

.earning-card>span:first-child {
    border: none;
}

.earning-card>span {
    display: table-cell;
    font-size: 27px;
    line-height: 27px;
    font-weight: 400;
    color: #303030;
    text-decoration: none;
    padding: 0 5px;
    text-align: center;
    vertical-align: top;
    border-left: 1px solid #ddd;
}

.earning-card small, .earning-card>span small {
    display: block;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #1F2D41 ;
    text-align: center;
    padding: 0 0 15px;
    min-width: 125px;
} */

.net-income {
    border: 1px solid #E3E8EC;
    background-color: #E3E8EC;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px; 
}

.income-content {
    display: flex;
    flex-direction: column;
}

.list-content img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}