.medical-team .content {
    padding: 0px 120px 0 0px;
}
.jusity-content {
    text-align: justify;
}
.medical-team .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    /* min-height: 600px; */
}

@media (max-width: 1024px) {
    .medical-team .content {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    .medical-team .img {
        min-height: 400px;
    }

    .medical-team .content {
        padding-top: 30px;
        text-align: center;
    }
    .btn-center {
        justify-content: center;
        margin-top: 30px;
    }
}

@media (max-width: 575px) {
    .medical-team .img {
        min-height: 200px;
    }
}