

/* .main {
    display: flex;
    flex-wrap: wrap;
    background-color: #F3F3F3;
    height: 100%;
  } */

  .hoc-container{
    max-height: 100vh;
    overflow: hidden;
  }
  .main-wrapper {
    flex: 1 0 0%;
    padding: 20px 15px 30px 15px;
    overflow: auto;
    width: 100%;

  }
  .second-col {
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
}

@media only screen and (max-width: 992px) {
  .main-wrapper {
    flex: 1 0 0%;
    padding: 20px 10px 30px 10px;
    overflow: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 574px) {
  .main-wrapper {
    flex: 1 0 0%;
    padding: 20px 10px 30px 10px;
    overflow: auto;
    width: 100%;
  }
}