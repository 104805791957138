.balance-content {
    background-color: #011F4B;
    border-radius: 0 0 10px 10px;
}

.wallet-content-details {
    padding-left: 20px;
}
.balance-content h3 {
    color: white !important;
}

.wallet-cards {
    position: relative;
    padding: 20px;
    flex: 0 0 48%; 
    max-width: 48%; 
    border-radius: 10px;
    background-color: #F7F3E3;
    height: 130px;
    margin-top: 20px;
}

.card-color {
    background-color: #C5C5F1 !important;
}
.wallet-cards img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 100px;  
    height: auto;
}

.wallet-cards h6 {
    position: absolute;
    bottom: 10px;
    left: 10px;
    margin: 0;
}

.balance-content {
    padding: 20px 0;
}

/* For Tablets */
@media (min-width: 768px) and (max-width: 991.98px) {
    .wallet-cards img {
        width: 70px; 
    }

    .wallet-cards {
        padding: 15px; 
    }
}

/* For Mobile */
@media (max-width: 767.98px) {
    .balance-content {
        text-align: center;
    }

    .wallet-cards {
        padding: 10px;
    }

    .wallet-cards img {
        width: 100px; 
    }
    .actions-wallet {
        margin-top: 20px;
    }
}






/* Transaction List */

.list-card {
    background-color: #f3f3f3;
    border-radius: 10px;
}
.list-card-content {
    padding: 10px 20px;
    align-items: center;
}
.transactionimage {
    width: 50px; /* example size, adjust as necessary */
    height: 50px;
    border-radius: 50%; /* if you want the image to be circular */
}