.navbar-brand {
  font-weight: 700;
  font-size: 28px;
  color: white !important;
}
.logoimage {
  height: 50px;
}
.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid #e1e4e8;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.navbar.colorChange {
  background-color: white;
  border-bottom: 1px solid #e1e4e8;
}

/* Add some styles to the sticky navbar, for example, a box-shadow */
.navbar.sticky {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar .ul {
  margin: 0 auto;
}
.nav-link {
  font-weight: 700;
  color: #333333 !important;
}
.navbar-expand-lg .navbar-nav .nav-item .nav-link {
  text-align: center;
}

.btn-success {
  background-color: #005b96;
  border: none;
}
.btn-success:hover {
  background-color: #011f4b;
  border: none;
}

.btn-outline-info {
  border: 1px solid #011f4b;
}

.btn-outline-info:hover {
  background-color: #005b96;
  border: none;
  color: white;
}

.login-nav-btn {
  color: #011f4b;
}
.login-nav-btn:hover {
  color: white;
}
.nav-btn {
  color: white !important;
}
.nav-btn:hover {
  color: white !important;
}

@media (max-width: 768px) {
  .navbar-nav {
    margin-left: 0px;
  }
  .auth-buttons {
    text-align: center;
  }
}
