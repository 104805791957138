.header {
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  padding: 0.75rem; /* 24px */
  align-items: center;
  gap: 0.75rem; /* 12px */
  margin-bottom: 0.75rem; /* 12px */
}
.header-title {
  font-weight: 600;
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  color: rgb(17 24 39);
}

  .icon-container img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
 
  .icon-container {
    width: 40px;
    height: 40px;
    position: relative;
    display: inline-block;  
    vertical-align: middle; 
}

.status-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: green;
    border: 2px solid white;
    position: absolute;
    bottom: 0;
    right: 0;
}

.dropdown-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    vertical-align: middle;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
}

.icon-container {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-right: 5px;
}


.username {
    vertical-align: middle; 
}

/* .nav-link { 
    padding: 0 !important;
} */

.dropdown-menu {
    top: 25% !important; 
    right: 50px !important;
}

/* Notification Styling */
.notification-dropdown {
    position: relative;
}

.dropdown-content {
    position: absolute;
    top: 215%;
    left: -291px;
    width: 500px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    background-color: #ffffff;
    overflow: auto;
    max-height: 80vh; 
    z-index: 9999;
}

.notification-item {
    display: flex;
    align-items: center; 
    padding: 10px;
    position: relative; 
}
.notification-image {
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    margin-right: 10px; 
}

.notification-details {
    flex-grow: 1; 
}
.notification-item.unread {
    background-color: #f3f3f3; 
}

.unread-indicator {
    width: 15px;
    height: 15px;
    background-color: #005B96; 
    border-radius: 50%; 
    margin-right: 10px;
}

.notification-item:last-child {
    border-bottom: none;
}

.notification-title {
    font-weight: bold;
    padding-top: 15px;
    padding-left: 10px;
}

.notification-text {
    margin-top: 5px;
    color: #666;
}
.notification-item:hover {
    background-color: #f0f0f0;
}
.notification-badge {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: #005B96;
    color: white;
    padding: 5px 10px;
    border-radius: 50%;
    font-size: 12px;
}

.notification-all {
    padding-top: 15px;
    padding-right: 10px;
}

.dropdown-toggle::after {
    content: none !important;
}