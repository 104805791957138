.reportcard {
    background-color: #f3f3f3;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.reportstext {
    padding-top: 20px;
    padding-left: 20px;
}

.infodetails {
    padding-left: 20px;
}

.iconsreport {
    height: 15px;
    filter: invert(59%) sepia(29%) saturate(10%) hue-rotate(322deg) brightness(97%) contrast(91%);
}

.report-text {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.reportcard .bottom {
    display: flex;
    flex-direction: row-reverse;
  }
  
  .reportcard .bottom button {
    border: none;
    font-size: 0.9rem;
    padding: 0 0.6rem;
    padding-bottom: 0.1rem;
    border-radius: 5px;
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 0.6rem;
  }
  
  
  .outline-btn-del {
    font-size: 0.9rem;
    padding: 0 0.6rem;
    padding-bottom: 0.1rem;
    border-radius: 5px;
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 0.6rem;
    background-color: transparent;
    border: 2px solid #FF0000 !important;
    transition: background-color 0.2s, color 0.2s;
    color: #FF0000;
  }

  .outline-btn {
    font-size: 0.9rem;
    padding: 0 0.6rem;
    padding-bottom: 0.1rem;
    border-radius: 5px;
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 0.6rem;
    background-color: transparent;
    border: 2px solid #011f4b !important;
    transition: background-color 0.2s, color 0.2s;
    color: #011f4b;
  }

.btniconview {
  width: 20px;
  width: 20px;
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(18deg) brightness(103%) contrast(100%);
}
.btniconshare {
  width: 20px;
  width: 20px;
  filter: invert(10%) sepia(56%) saturate(2525%) hue-rotate(202deg) brightness(94%) contrast(105%);
}
.btnicondel {
  width: 20px;
  width: 20px;
  filter: invert(19%) sepia(91%) saturate(6377%) hue-rotate(1deg) brightness(106%) contrast(127%);
}
  /* Button At Bottom */

  .fixed-icon-button {
    position: fixed;
    right: 20px;    
    bottom: 20px;   
    z-index: 1000; 
    height: 45px;
    width: 45px;
    filter: invert(16%) sepia(100%) saturate(2835%) hue-rotate(188deg) brightness(97%) contrast(102%);
}
.plusicon {
  height: 50px;
}
@media (max-width: 425px) {
.btniconview {
  width: 13px;
  width: 13px;
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(18deg) brightness(103%) contrast(100%);
}
.btniconshare {
  width: 13px;
  width: 13px;
  filter: invert(10%) sepia(56%) saturate(2525%) hue-rotate(202deg) brightness(94%) contrast(105%);
}
.btnicondel {
  width: 13px;
  width: 13px;
  filter: invert(19%) sepia(91%) saturate(6377%) hue-rotate(1deg) brightness(106%) contrast(127%);
}
}