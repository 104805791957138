.shifts-section {
  margin-bottom: 5%;
}

.shifts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -2px;
  margin-right: -3px;
}

.timing {
  box-sizing: border-box;
  border: 1px solid #999999;
  padding: 5px;
  display: inline-block;
  margin-bottom: 5px;
  margin-left: 2px;
  margin-right: 3px;
}

.custom-list {
  margin-top: 10px;
  overflow: auto;
}

.custom-list .list-group-horizontal {
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.custom-list li {
  border: 0;
  text-align: center;
  padding: 0 15px;
  font-size: 12px;
}

.custom-list li p {
  margin-bottom: 0;
  font-size: 18px;
}

.timing.selected {
  background-color: #03396C; /* or any other color */
  color: white;
}
.active-day {
  text-decoration: underline;
}
