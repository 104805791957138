.profile-status {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #005B96;
    margin-bottom: 20px;
   
}

.statusImage {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;   
    padding-top: 20px;
    padding-bottom: 20px;   
}

.profile-completeness {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 50px;
}

.status-bar {
    flex-grow: 1;
    height: 5px;
    background-color: #E0E0E0; 
    border-radius: 10px;
    margin-right: 10px;
    overflow: hidden;
}

.filled {
    height: 100%;
    background-color: #4CAF50; 
    border-radius: 10px 0 0 10px;
}
