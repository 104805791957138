#qualit-care .content {
    padding: 0px 120px 0 0px;
}

#qualit-care .content h2 {
    text-align: left !important;
}

#qualit-care .content p {
    text-align: justify !important;
}

#qualit-care .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 8px !important;
    /* min-height: 600px; */
}

@media (max-width: 1024px) {
    #qualit-care .content {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 992px) {
    #qualit-care .img {
        min-height: 400px;
    }

    #qualit-care .content {
        padding-top: 30px;
    }
}

@media (max-width: 575px) {
    #qualit-care .img {
        min-height: 200px;
    }
}