#terms-banner {
  width: 100%;
  height: 40vh;
  background: url("../../../../../public/assets/webImages/ondoc.PNG") top center;
  background-size: cover;
  position: relative;
  padding: 0;
}

#terms-banner:before {
  content: "";
  background: linear-gradient(
    270deg,
    rgba(1, 31, 75, 0) 3.58%,
    rgba(1, 31, 75, 0.206207) 62.21%,
    rgba(1, 31, 75, 0.5) 96.42%,
    rgba(1, 31, 75, 0.34654) 100%
  );
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#terms-banner .terms-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#terms-banner h2 {
  color: #eee;
  font-size: 24px;
}

@media (min-width: 1024px) {
  #terms-banner {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #terms-banner h2 {
    font-size: 18px;
    line-height: 24px;
  }
}
